import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PublicRoutes = () => {
    let auth = { 'token': sessionStorage.getItem('token') ? false : true }
    return (
        auth.token ? <Outlet /> : <Navigate to='/tracking/inicio' />
    )
}

export default PublicRoutes;