import localForage from "localforage";
import { CronJob } from "cron";

import { tracking } from "../data/data";
import moment from "moment";
import localforage from "localforage";

let isRunning = false
const job = new CronJob("1 * * * * *", async () => {  // se ejecutaría cada minuto
  if (!isRunning) {
    isRunning = true
    if (navigator.onLine) {
      // console.log('online')
      const keys = await localForage.keys()
      let actualizar = false;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]


        let a = key.split('-')
        if (a[0] === 'add') {
          try {
            const value = await localForage.getItem(key)
            value.map((item, i) => {
              tracking.guardar(item).then((res) => {
                console.info('ok')
                actualizar = true;
                sessionStorage.setItem('caducidad', res.data.usuario.caducidad);
              }).catch((err) => {
                console.error(err)
              })
              // axios.post(process.env.REACT_APP_API_URL+'/api/webapp/', item)
              // .then(() => {console.info('ok')})
            })
            await localForage.removeItem(key)

          } catch (err) {
            console.error(err)
          }
        }
      }
      if (actualizar) {
        tracking.getDatos({ action: 'loadData', token: sessionStorage.getItem('token') }).then((res) => {
          // console.log(res)
          if (res.data.hospitales) {
            localForage.setItem('hospitales', res.data.hospitales)
          }
          if (res.data.productos) {
            localForage.setItem('products', res.data.productos)
          }
        }).catch((err) => {
          console.log(err)
        })
      }
      let caducidad = sessionStorage.getItem('caducidad');
      let now = moment().format('X')
      if(now > caducidad){
          alert('Su sesión ha caducado, por favor inicie sesión nuevamente');
          sessionStorage.clear();
          localforage.clear();
          localStorage.clear();
          window.location.href = '/'
      }
    }
    isRunning = false
  }
})
job.start()