import React, { useEffect } from 'react'
import './App.css';

import {
  Route,
  Routes,
  BrowserRouter as Router
} from 'react-router-dom'

import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'

const AutologinComponent = React.lazy(() => import('./pages/Autologin'));
const InicioComponent = React.lazy(() => import('./pages/Inicio'));
const FormularioComponent = React.lazy(() => import('./pages/Formulario'));
const PasosComponent = React.lazy(() => import('./pages/Pasos'));
const IndexComponent = React.lazy(() => import('./pages/Index'));

const App = () => {

  return (
    <>
      <Router>
        <React.Suspense fallback={'Cargando...'}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              {/* <Route exact path='/tracking/inicio' element={<InicioComponent />} />
              <Route exact path='/tracking/formulario' element={<FormularioComponent />} /> */}
              {/* <Route exact path='/tracking/pasos' element={<PasosComponent />} /> */}
              <Route exact path='/tracking' element={<IndexComponent />} />
            </Route>
            <Route element={<PublicRoutes />}>
              <Route path="/autologin" name="Login" element={<AutologinComponent />} />
            </Route>
            <Route path="/*" element={<RedirectToExternal url={process.env.REACT_APP_CTM_URL} />} />
          </Routes>
        </React.Suspense>
      </Router>
    </>
  );
}

const RedirectToExternal = ({url}) => {
  window.location.href = url;
  return null; // No se muestra ningún componente
};

export default App;
