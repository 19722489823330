import axios from 'axios'
export const url_backend = process.env.REACT_APP_API_URL

export const auth = {
    autologin: function(action, autolog) {
        const datos = {
            action: 'autologin',
            autolog: autolog
        }
        return axios.post(url_backend+`/api/login/?autolog=${autolog}`)
    },
}

export const tracking = {
    guardar: function(datos) {
        // axios.defaults.headers.common['Authorization'] = "Bearer " + sessionStorage.getItem('token')
        return axios.post(url_backend+`/api/data/`, datos)
    },
    getDatos: function(datos) {
        return axios.post(url_backend+`/api/data/`, datos)
    }
}